<template>

<app-content :loading="is.loading">

	<app-content-head :image="match.user.avatar" :title="match.user.name" back="Convention.Schedule.Discover.Matchmaker" />

	<div class="progress" v-if="!is.loading">
		<div class="progress-item" :class="{'is-active': step === 1, 'is-complete': step > 1}">{{ stepGame }}</div>
		<div class="progress-item" :class="{'is-active': step === 2, 'is-complete': step > 2}">{{ stepTime }}</div>
		<div class="progress-item" :class="{'is-active': step === 3}">3. Send proposal</div>
	</div>

	<app-content-body>

		<com-games :match="match" v-if="step === 1" v-on:select="onGameSelect" />
		<com-schedule :match="match" v-if="step === 2" v-on:select="onScheduleSelect" />
		<com-submit :match="match" :proposal="proposal" v-if="step === 3" />

	</app-content-body>

</app-content>

</template>

<script>

import Vue from 'vue'

export default {

	components: {
		'com-games': () => import('./conventionschedulediscovermatch/Games'),
		'com-schedule': () => import('./conventionschedulediscovermatch/Schedule'),
		'com-submit': () => import('./conventionschedulediscovermatch/Submit')
	},

	data: function() {

		return {
			is: {
				loading: true,
				buddying: false
			},
			proposal: {
				game: 0,
				day: 0,
				time: 0,
				end: 0
			},
			step: 1,
			match: {
				games: [],
				interests: {},
				seats: [],
				user: [],
				days: [],
				durations: [],
				times: []
			}
		}

	},

	computed: {

		isBuddy: function() {

			return false //this.$store.getters['user/buddies/is'](this.match.user.id)

		},

		stepGame: function() {

			return (this.proposal.game) ? this.$_.findWhere(this.match.games, { id: this.proposal.game }).name : '1. Pick a game'

		},

		stepTime: function() {

			if (this.proposal.day) {

				var time = []
				//var modifier = (this.$store.getters['user/settings/time']) ? 0 : 12

				time.push(this.$constants.schedule.day[this.proposal.day])
				time.push(', ')

				time.push(this.match.times[this.proposal.time])

				time.push(' - ')

				time.push(this.match.times[this.proposal.end])

				return time.join('')

			} else {

				return '2. Pick a day and time'

			}

		}

	},

	methods: {

		onBuddyClick: function() {

			//this.$action('buddying', 'user/buddies/toggle', this.match.user.id)

		},

		onGameSelect: function(game) {

			this.proposal.game = game 
			this.step++

		},

		onScheduleSelect: function(data) {

			this.proposal.day = data.day
			this.proposal.time = data.time
			this.proposal.end = data.end

			this.step++

		}

	},

	created: async function() {

		await this.$api.get(['convention', 'schedule', 'discover', 'matchmaker', this.$route.params.id]).then(function(json) {

			Vue.set(this, 'match', json.match)

			this.is.loading = false

		}.bind(this))

	}

}

</script>

<style scoped>

.progress {
	display: flex;
	height: 40px;
}

.progress-item {
	width: 33.3%;
	height: 40px;
	background-color: #4d5b6e;
	color: #bdbdbd;
	display: flex;
	font-weight: 400;
	align-items: center;
	padding: 0px 20px;
	font-size: 16px;
	transition: all 100ms linear;
	justify-content: center;
}

.is-mobile .progress-item {
	font-size: 13px;
	padding: 0px 10px;
}

.progress-item.is-complete,
.progress-item.is-active {
	background-color: #ffbe7a;
	color: #4d5b6e;
	z-index: 2;
}

.progress-item.is-complete {
	color: #cb8236;
}

.progress-item.is-active:after {
	position: absolute;
	right: -10px;
	top: 0px;
	content: '';
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 20px 0 20px 10px;
	border-color: transparent transparent transparent #ffbe7a;
}

</style>